.modal {
    background-color: rgba(0, 0, 0, 0.6);
    transition: $dash-transition-03;
    position: fixed;
    visibility: none;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: none;
    z-index: 0;
}

.modalActive {
    visibility: visible;
    display: block;
    z-index: 30;
}

@media (max-width: 768px) {

    .modal span {
        top: 2rem;
        right: 2rem;
    }
}