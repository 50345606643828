.container {
    width: 100%;
    height: 100svh;
    @include flex(column, center, center, 0);

    h2 {
        font-weight: 300;
        font-size: 1.4rem;
        text-transform: uppercase;
        margin-top: .5rem;
    }

    img {
        height: 7rem;
        margin-top: 3rem;
    }
}

@media (max-width: 768px) {
    .container {

        img {
            height: 7rem;
        }
    }
}