.dashboard-container {
    display: flex;
    width: 100%;
    height: 100%;
}

.dashboard__main {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    z-index: 6;

    .main__section {
        width: 100%;
        height: calc(100vh - 5rem - 5rem);
        background-color: $dash-second;
        padding: 0 2rem 4rem 2rem;
        overflow-y: scroll;
        color: $dash-txt;
    }

    .main__section-nosearch {
        width: 100%;
        height: calc(100vh - 5rem);
        background-color: $dash-second;
        padding: 2rem;
        overflow-y: scroll;
        color: $dash-txt;
    }

    .main-flex {
        @include flex(row, flex-start, flex-start, 3rem);
    }

    textarea,
    input,
    select,
    option {
        font-family: 'Nunito', sans-serif;
        font-weight: 400;
        text-decoration: none;
        font-size: 1.6rem;
        background-color: $dash-input;
        border: $dash-border;
        border-radius: $dash-radius;
        color: $dash-txt-different;

        &:focus {
            outline: none;
        }
    }
}

.category__layout-grid {
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-gap: 2rem
}

.category__layout-flex {
    display: flex;
    flex-direction: row;
    gap: 2rem
}

.color-danger {
    color: $dash-danger;
}

.color-success {
    color: $dash-success;
}

.color-info {
    color: $dash-info;
}

.color-danger-muted {
    color: $dash-danger-muted;
}

.color-success-muted {
    color: $dash-success-muted;
}

.color-info-muted {
    color: $dash-info-muted;
}

.mt-1 {
    margin-top: 1rem;
}

.ml-1 {
    margin-left: 1rem;
}

.mr-1 {
    margin-right: 1rem;
}

.ml-18 {
    margin-left: 1.8rem;
}

.ml-15 {
    margin-left: 1.5rem;
}

.ml-2 {
    margin-left: 2rem;
}

.mr-1 {
    margin-right: 2rem;
}

.mr-2 {
    margin-right: 2rem;
}

.mr-3 {
    margin-right: 3rem;
}

.mr-4 {
    margin-right: 3rem;
}

.mb-2 {
    margin-bottom: 2rem;
}

.mb-05 {
    margin-bottom: .5rem;
}

.mb-1 {
    margin-bottom: 1rem;
}

.mb-2 {
    margin-bottom: 2rem;
}

.mb-3 {
    margin-bottom: 3rem;
}

.mt-1 {
    margin-top: 1rem;
}

.mt-2 {
    margin-top: 2rem;
}

.mt-3 {
    margin-top: 3rem;
}

.p-1 {
    padding: 1rem;
}

.p-2 {
    padding: 2rem;
}

.order__product-count {
    width: 2rem;
    height: 2rem;
    background-color: $dash-danger;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;

    h3 {
        font-size: 1.6rem;
        text-decoration: none;
    }
}