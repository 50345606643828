.search {
    @include flex(row, center, flex-start, 0);

    padding: 2rem 2rem 0rem 2rem;
    background-color: $dash-second;
    color: $dash-txt;
    padding-bottom: 2rem;

    .search-input {
        border: $dash-border;
        display: flex;
        align-items: center;
        width: 30rem;
        padding-left: 1rem;
    }

    input[type=text] {
        height: 3.5rem;
        background-color: transparent;
        border: none;
        color: $dash-txt;
        margin: 0rem;
        padding: 0rem;

        &:focus {
            outline: none;
        }
    }
}

@media screen and (max-width: 768px) {
    .search {

        .search-input {
            width: 100%;
        }
    }
}