.login-container {
    @include flex(row, center, center, 0);
    position: relative;
    min-height: 75%;

    .login-card {
        @include flex(column, center, center, 0);
        position: absolute;
        z-index: 6;
        left: calc(50% - 17rem);
        width: 32rem;
        height: 44rem;
        background-color: #f5f5f5;
        box-shadow: $dash-box-shadow;
        // padding: 5rem 2rem 2rem 2rem;
        padding: 2rem;
        margin-bottom: 4rem;

        form {
            width: 100%;
            // height: 29rem;
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: space-between;
            margin-top: 1rem;
            margin-bottom: 5rem;

            input::placeholder {
                color: #979797;
                font-weight: 100;
            }

            .form-imputs {
                width: 100%;
                display: flex;
                align-items: center;
                flex-direction: column;
                justify-content: flex-start;
            }

            .inputs {
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 0 auto;
                width: 100%;
                margin-top: 2rem;
                position: relative;

                p {
                    font-size: 1.6rem;
                }

                input,
                textarea {
                    border: solid 1px #d8d8d8f8;
                    width: 100%;
                    padding: .5rem;
                    font-size: 1.5rem;
                    height: 3.2rem;
                    letter-spacing: .1rem;
                }

                input:focus {
                    outline: 1px solid var(--color-acent);
                }

                .remember {
                    height: 1.6rem;
                    width: 1.6rem;
                    margin-right: 1rem;
                    border: solid 1px #d8d8d8f8;
                }

                label {
                    color: var(--color-acent);
                    line-height: 1.5rem;
                    font-weight: 300;
                }

                .btn {
                    width: 50%;
                    margin-top: 2rem;
                }
            }

            .check {
                display: flex;
                align-items: center;
                justify-content: flex-start;
            }
        }
    }

    .signup {
        height: 53rem;
    }

    .login-card-header {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        margin-bottom: 4rem;

        h1 {
            font-size: 1.6rem;
            text-transform: uppercase;
            text-align: center;
            letter-spacing: 2px;
            font-weight: lighter;
            padding: 0rem;
        }
    }
}


.login-container .login-card .switch {
    position: absolute;
    top: 1rem;
    left: 2rem;

    span {
        color: $dash-accent;
        cursor: pointer;
        text-decoration: underline;
    }
}


.loginCard {
    transform: rotateY(0deg);
    transition: var(--transition);
    transition: transform .3s linear;
    opacity: 1;
    z-index: 10;
}

.signupCard {
    transform: rotateY(90deg);
    /* transition: var(--transition); */
    transition: transform .3s linear;
    opacity: 0;
    z-index: 9;
}

.loginActive {
    transform: rotateY(90deg);
    opacity: 0;
    z-index: 9;
}

.sigupActive {
    transform: rotateY(0deg);
    opacity: 1;
    z-index: 10;
}

.visible__pass {
    position: absolute;
    right: 1rem;
    cursor: pointer;
    color: #979797;
}

.visible__pass:hover {
    color: var(--color-acent);
}

.pass__reset {
    text-decoration: underline;
    color: $dash-accent;
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 2rem;
    cursor: pointer;
}

@media (max-width: 768px) {
    .login-container {
        height: 60rem;
    }
}