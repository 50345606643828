.header {
    position: sticky;
    top: 0;
    left: 0;
    z-index: 5;
    background-color: $dash-main;
    min-width: 100%;
    height: 5rem;
    border-bottom: $dash-border;
    padding: 1rem 2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: $dash-transition-015;
    color: $dash-txt;

    &__left {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 20rem;
    }

    &__right {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        // width: 16rem;

        & .dash__user {
            text-transform: capitalize;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-end;
            line-height: 1.2;
            margin-right: 2rem;

            & span:nth-child(2) {
                color: $dash-accent;
                font-style: italic;
                font-size: 1.3rem;
            }
        }
    }

    .close-menu {
        display: none;
    }

    .hamburger {
        display: none;
    }
}