.icon-small {
    font-size: 1.8rem;
    text-align: center;
    cursor: pointer;
}

.icon-main {
    font-size: 2rem;
    text-align: center;
    cursor: pointer;
}

.ico-publish {
    // margin: .5rem;
    background-color: $dash-second;
    border-radius: .6rem;
    text-align: center;
    cursor: pointer;
    width: 2.5rem;
    height: 2.5rem;
}

.icon-xl {
    font-size: 2.8rem;
    text-align: center;
    cursor: pointer;
}

.icon-xxl {
    font-size: 3.2rem;
    text-align: center;
    cursor: pointer;
}

.icon-bag {
    background-color: $dash-danger;
}

.icon-dash-green {
    color: $dash-success;
}

.icon-dash-danger {
    color: $dash-danger;

    &:hover {
        color: $dash-danger-hover;
    }
}

.icon-dash-info {
    color: $dash-info;

    &:hover {
        color: $dash-info-hover;
    }
}

.icon-dash-success {
    color: $dash-success;

    &:hover {
        color: $dash-success-hover;
    }
}

.icon-dash-submenu {
    position: absolute;
    transform: translateX(12rem) rotate(0deg);
    transition: $dash-transition-03;
}

.group.toggleHeader .icon-dash-submenu {
    transform: translateX(12rem) rotate(180deg);
}

// React LINK  stylinga for icons in Menu
.link-style-icon {
    font-size: 1.6rem;
    color: $dash-txt;
    display: flex;
    align-items: center;
}


// =======================================
// Buttons Start
.btn__dash-big {
    width: 15rem;
    height: 3.6rem;
    padding: .6rem 1rem;
    border: none;
    border-radius: 30rem;
    cursor: pointer;
    color: $dash-button-txt;
    font-size: 1.4rem;
    font-weight: bold;
    transition: $dash-transition-015;
    @include flex-row-center-center;
}

.btn__dash-regular {
    @include flex-row-center-center;
    min-width: 14rem;
    height: 4rem;
    padding: .6rem 1.4rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    color: $dash-button-txt;
    font-size: 1.6rem;
    transition: $dash-transition-015;
}

.btn-minwidth {
    min-width: 14rem;
}

.dash-button-success {
    background-color: $dash-success;

    &:hover {
        background-color: $dash-success-hover;
        cursor: pointer;
    }
}

.dash-button-danger {
    background-color: $dash-danger;

    &:hover {
        background-color: $dash-danger-hover;
        cursor: pointer;
    }
}

.dash-button-info {
    background-color: $dash-info;

    &:hover {
        background-color: $dash-info-hover;
        cursor: pointer;
    }
}

.menu__button {
    @include flex-row-center-center;

    position: absolute;
    left: 0;
    top: 1rem;
    width: 2.8rem;
    height: 2.8rem;
    margin-left: 1rem;
    background-color: $dash-accent;
    border-radius: 50%;
    transition: $dash-transition-015;
    color: $dash-button-txt;

    // width: 2.5rem;
    // height: 3rem;
    // border-radius: 0 30rem 30rem 0;
    &:hover {
        background-color: $dash-hover;
        cursor: pointer;
    }

    .iconRotate {
        transform: rotate(180deg);
        transition: $dash-transition-015;
    }
}

.switcher {
    @include flex-row-center-center;

    position: relative;
    min-width: 3.6rem;
    height: 2.6rem;
    background-color: $dash-main;
    border-radius: 10rem;
    margin: 0 1.5rem;
    border: $dash-border;

    &__slider {
        position: absolute;
        width: 1.8rem;
        height: 1.8rem;
        border-radius: 10rem;
        transform: translate(.5rem, -.03rem);
        background: $dash-accent;
        transition: $dash-transition-015;
        margin: 0 !important;

        &:hover {
            cursor: pointer;
        }
    }


    // .slider-move {
    //     transform: translateX(-.5rem);
    // }
}

// Buttons End
// =======================================