.details__window {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 100;
    width: 100rem;
    height: 100vh;
    transform: translateX(100%);
    transition: $dash-transition-03;
    display: flex;
    background-color: $dash-main;
    border-left: $dash-border;
    overflow-y: scroll;

    &-details {
        width: 100%;
        margin: 0px;
    }

    & .icon-position {
        position: fixed;
        // left: 90%;
        right: 2rem;
        top: 2rem;
    }
}

.detailsActive {
    transform: translateX(0%);
}

@media screen and (max-width:1024px) {
    .details__window {
        width: 100vw;
        // width: calc(100% + 26rem);
    }
}