.mobile__container {
    @include flex(column, flex-start, flex-start, 0);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $dash-main;
    z-index: 100%;
    padding: 2rem 1rem;
    transform: translateX(-100%);
    transition: $dash-transition-015;

    .mobile-menu-top {

        @include flex(column, flex-start, flex-start, 0);
        width: 100%;
        height: 6rem;
        margin-left: 1.8rem;
        margin-bottom: 3rem;
        cursor: pointer;

        img {
            height: 6rem;
        }
    }

    .aside__menu-bottom-content {
        width: 100%;

    }

}

.mobileActive {
    transform: translateX(0);
}

.closeMobileMenu {
    position: absolute;
    top: 2rem;
    right: 2rem;
    cursor: pointer;
    font-size: 2.3rem;
}

.moveIcon {
    margin-left: 12.8rem;
}