.products__container {

    display: grid;
    grid-template-columns: repeat(3, 1fr);
    min-height: 8.2rem;
    gap: 1rem;

    .single__sontainer {
        width: 100%;
        min-height: 8rem;
        background-color: $dash-main;
        border-radius: 5px;
        padding: 1.5rem;
        transition: $dash-transition-015;
        box-shadow: $dash-box-shadow;
        position: relative;


        &:hover {
            transform: translateY(-.3rem);

            &::after {
                content: '';
                display: block;
                width: 100%;
                height: .2rem;
                position: absolute;
                bottom: 0;
                left: 0;
                background-color: $dash-accent;
                border-radius: 0px 0px 5px 5px;
            }
        }

        h3 {
            font-weight: 300;
            color: $dash-accent;
        }

        .product-header {
            @include flex(row, center, space-between, 0rem);
            margin-bottom: 1.5rem;

            .product-info {
                @include flex(row, center, center, 2rem);
            }

            .product-options {
                @include flex(row, center, space-between, 1rem);
            }
        }


        .product-middle {
            @include flex(row, flex-start, space-between, 2rem);
            // margin-bottom: 1rem;

            img {
                height: 9rem;
            }
        }

        .product-footer {
            @include flex(row, flex-end, space-between, 0);
        }
    }
}

@media screen and (max-width:1280px) {
    .products__container {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media screen and (max-width:768px) {
    .products__container {
        grid-template-columns: 1fr;
    }
}