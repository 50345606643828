.message__txt{
    min-width: 36rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    color: gray;
    height: 100%;
    margin-left: .4rem;
    white-space: pre;
}

.message__txt h3 {
    font-size: 1.4rem;
    width: 100%;
}

.message__txt p {
    font-size: 1.5rem;
    width: 100%;
}

@media (max-width: 480px) {
    .message__txt{
        align-items: center;
    }
}