.product {
    // position: relative;
    box-shadow: $dash-box-shadow;
    background-color: $dash-main;
    border-radius: .5rem;
    min-height: calc(100vh - 9rem);

    .category__container-header {
        background-color: $dash-third;
        border-radius: .5rem .5rem 0 0;

    }

    .product__container {
        position: relative;
        width: 100%;
        display: grid;
        grid-template-columns: 2fr 2fr 3fr;
        // display: flex;
        // flex-direction: row;


        &-inputs {

            // min-height: 100%;
            padding: 2rem;

            // klasa kontejnera za upload slika
            .fileContainer {
                background: $dash-input;
                border-radius: $dash-radius;
                border: $dash-border;

                .chooseFileButton {
                    @include flex(row, center, center, 0);
                    padding: 1rem;
                    border-radius: 50%;
                    background-color: $dash-third;
                    margin-bottom: 1.5rem;

                    &:hover {
                        background-color: $dash-main;
                    }
                }

                .deleteImage {
                    @include flex(row, center, center, 0);
                    background-color: $dash-danger;
                    font-size: 1.8rem;
                    width: 2.6rem;
                    height: 2.6rem;
                    transition: $dash-transition-015;

                    &:hover {
                        background-color: $dash-danger-muted;
                    }
                }
            }

            .select__group {
                display: flex;
                width: 100%;
                gap: 1rem;

                &-content {
                    width: 50%;
                }
            }

            input[type=text],
            select {
                width: 100%;
                height: 3.6rem;
                padding: 0 1rem;
                display: flex;
                align-items: center;
                margin-bottom: 1rem;
            }

            textarea {
                width: 100%;
                margin-bottom: 1rem;
                padding: 1rem;
                resize: none;
            }

            input[type=file] {
                display: none;
                width: 100%;
            }

            label {
                font-size: 1.5rem;
            }

            p {
                font-size: 1.6rem;
            }
        }

        button {
            position: absolute;
            bottom: 0rem;
            right: 2rem;
            margin-bottom: 1rem;
            // left: 100%;
            // margin: 0 2rem;
        }
    }

}



.input-flex {
    display: flex;
    flex-direction: column;
}

.label-success {
    color: $dash-success-muted;
}

.label-danger {
    color: $dash-danger-muted;
}

.input-file-styling {
    width: 100%;
    height: 3.6rem;
    background-color: $dash-input;
    border-radius: $dash-radius;
    border: $dash-border;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}