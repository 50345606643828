.category-list {
    min-height: 100%;
    width: 100%;

    .category__container-inputs {
        padding: 1rem;
        width: 100%;

        .list__search {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            height: 4rem;
            margin-bottom: 1rem;
            width: 100%;

            .search-input {
                border: $dash-border;
                display: flex;
                align-items: center;
                width: 25rem;
                padding-left: 1rem;
            }

            input[type=text] {
                height: 3.3rem;
                background-color: transparent;
                border: none;
                color: $dash-txt;
                margin: 0rem;
                padding: 0rem;

                &:focus {
                    outline: none;
                }
            }
        }
    }
}