@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;500;600;700;800&family=Source+Sans+Pro:wght@200;300;400;600;700;900&display=swap');

*,
*::after,
*::before{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}


html{
  font-size: 62.5%;
  overflow-x: hidden;
  overflow-y: hidden;
}


body {
  font-family: 'Nunito', sans-serif;
  font-weight: 300;
  text-decoration: none;
  font-size: 1.6rem;
  /* background-color: #fff; */
  line-height: 1.6;
  letter-spacing: .05rem;
  outline: 0;
  -webkit-font-smoothing: subpixel-antialiased;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: hidden;
  overflow-x: hidden;
}

.fileContainer {
  background: #242430;
  border-radius: 4px;
  border: 1px solid #3b3b3b;
}

