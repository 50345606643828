.btn {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #242424;
    color: #ffffff;
    text-transform: uppercase;
    font-size: 1.2rem;
    padding: 1.0rem 1.5rem;
    text-align: center;
    border: 1px solid #242424;
    outline: none;
    width: 12rem;
    height: 4rem;
    text-decoration: none; 
    transition: 0.2s linear background-color; 
    position: relative;
}

.btn-with-100{
    min-width: 100%;
}

.btn:hover {
    cursor: pointer;
    background-color: #ffffff;
    color: #242424;
}

.btn:active {
    background: #242424; 
}

.btn-oposite {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    color: #242424;
    text-transform: uppercase;
    font-size: 1.2rem;
    padding: 1.0rem 1.5rem;
    text-align: center;
    border: 1px solid #242424;
    outline: none;
    width: 12rem;
    height: 4rem;
    text-decoration: none; 
    transition: 0.2s linear background-color; 
}

.btn-oposite:hover {
    cursor: pointer;
    background-color: #242424;
    color: #fff;
    border: 1px solid #242424;
}

.btn-oposite:active {
    background: #fff; 
}

/* @media (max-width: 960px) {
    .btn {
        width: 16rem !important;
    }
    
}

@media (max-width: 768px) {
    .btn {
        width: 100% !important;
    }
} */