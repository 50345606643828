.category__container {

    width: 100%;
    height: 28rem;
    background-color: $dash-main;
    border-radius: .5rem;
    box-shadow: $dash-box-shadow;
    // margin-bottom: 2rem;


    &-header {
        height: 3.5rem;
        background-color: $dash-third;
        border-radius: .5rem .5rem 0 0;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        & h2 {
            font-size: 1.8rem;
            text-transform: capitalize;
            margin-left: 2rem;
        }
    }

    &-inputs {
        height: calc(100% - 4rem);
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        width: 100%;

        label {
            font-size: 1.5rem;
        }

        input[type=text],
        select {
            width: 100%;
            height: 3.6rem;
            padding: 0 1rem;
            display: flex;
            align-items: center;
            margin-bottom: 1rem;
        }

        .select-input {
            display: flex;
            flex-direction: column;
        }
    }

    form {
        width: 100%;

        .category__container-inputs-content-buttons {

            display: flex;
            justify-content: space-between;

            button {

                position: relative;
            }

            button .dash-button-regular {
                min-width: 5rem !important;
            }
        }
    }
}


.addCard-hide {
    display: none;
}

.updateCard {
    display: none;
}

.updateCard-show {
    display: block;
}


// Conteiner height for AddSubCategory component
.container-height {
    height: 35rem;
}