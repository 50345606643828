.dashboard-container {
  display: flex;
  width: 100%;
  height: 100%;
}

.dashboard__main {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  z-index: 6;
}
.dashboard__main .main__section {
  width: 100%;
  height: calc(100vh - 5rem - 5rem);
  background-color: #242430;
  padding: 0 2rem 4rem 2rem;
  overflow-y: scroll;
  color: #8d8d8d;
}
.dashboard__main .main__section-nosearch {
  width: 100%;
  height: calc(100vh - 5rem);
  background-color: #242430;
  padding: 2rem;
  overflow-y: scroll;
  color: #8d8d8d;
}
.dashboard__main .main-flex {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 3rem;
}
.dashboard__main textarea,
.dashboard__main input,
.dashboard__main select,
.dashboard__main option {
  font-family: "Nunito", sans-serif;
  font-weight: 400;
  text-decoration: none;
  font-size: 1.6rem;
  background-color: #242430;
  border: 1px solid #3b3b3b;
  border-radius: 4px;
  color: #f5f5f5;
}
.dashboard__main textarea:focus,
.dashboard__main input:focus,
.dashboard__main select:focus,
.dashboard__main option:focus {
  outline: none;
}

.category__layout-grid {
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-gap: 2rem;
}

.category__layout-flex {
  display: flex;
  flex-direction: row;
  gap: 2rem;
}

.color-danger {
  color: rgb(255, 82, 82);
}

.color-success {
  color: rgb(0, 170, 113);
}

.color-info {
  color: rgb(45, 178, 255);
}

.color-danger-muted {
  color: rgba(255, 82, 82, 0.6);
}

.color-success-muted {
  color: rgba(0, 170, 113, 0.6);
}

.color-info-muted {
  color: rgba(45, 178, 255, 0.6);
}

.mt-1 {
  margin-top: 1rem;
}

.ml-1 {
  margin-left: 1rem;
}

.mr-1 {
  margin-right: 1rem;
}

.ml-18 {
  margin-left: 1.8rem;
}

.ml-15 {
  margin-left: 1.5rem;
}

.ml-2 {
  margin-left: 2rem;
}

.mr-1 {
  margin-right: 2rem;
}

.mr-2 {
  margin-right: 2rem;
}

.mr-3 {
  margin-right: 3rem;
}

.mr-4 {
  margin-right: 3rem;
}

.mb-2 {
  margin-bottom: 2rem;
}

.mb-05 {
  margin-bottom: 0.5rem;
}

.mb-1 {
  margin-bottom: 1rem;
}

.mb-2 {
  margin-bottom: 2rem;
}

.mb-3 {
  margin-bottom: 3rem;
}

.mt-1 {
  margin-top: 1rem;
}

.mt-2 {
  margin-top: 2rem;
}

.mt-3 {
  margin-top: 3rem;
}

.p-1 {
  padding: 1rem;
}

.p-2 {
  padding: 2rem;
}

.order__product-count {
  width: 2rem;
  height: 2rem;
  background-color: rgb(255, 82, 82);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}
.order__product-count h3 {
  font-size: 1.6rem;
  text-decoration: none;
}

.icon-small {
  font-size: 1.8rem;
  text-align: center;
  cursor: pointer;
}

.icon-main {
  font-size: 2rem;
  text-align: center;
  cursor: pointer;
}

.ico-publish {
  background-color: #242430;
  border-radius: 0.6rem;
  text-align: center;
  cursor: pointer;
  width: 2.5rem;
  height: 2.5rem;
}

.icon-xl {
  font-size: 2.8rem;
  text-align: center;
  cursor: pointer;
}

.icon-xxl {
  font-size: 3.2rem;
  text-align: center;
  cursor: pointer;
}

.icon-bag {
  background-color: rgb(255, 82, 82);
}

.icon-dash-green {
  color: rgb(0, 170, 113);
}

.icon-dash-danger {
  color: rgb(255, 82, 82);
}
.icon-dash-danger:hover {
  color: rgba(255, 82, 82, 0.548);
}

.icon-dash-info {
  color: rgb(45, 178, 255);
}
.icon-dash-info:hover {
  color: rgba(45, 178, 255, 0.541);
}

.icon-dash-success {
  color: rgb(0, 170, 113);
}
.icon-dash-success:hover {
  color: rgba(0, 170, 113, 0.541);
}

.icon-dash-submenu {
  position: absolute;
  transform: translateX(12rem) rotate(0deg);
  transition: all 0.3s ease-in-out;
}

.group.toggleHeader .icon-dash-submenu {
  transform: translateX(12rem) rotate(180deg);
}

.link-style-icon {
  font-size: 1.6rem;
  color: #8d8d8d;
  display: flex;
  align-items: center;
}

.btn__dash-big {
  width: 15rem;
  height: 3.6rem;
  padding: 0.6rem 1rem;
  border: none;
  border-radius: 30rem;
  cursor: pointer;
  color: #e2e2e2;
  font-size: 1.4rem;
  font-weight: bold;
  transition: all 0.15s ease-in-out;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.btn__dash-regular {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  min-width: 14rem;
  height: 4rem;
  padding: 0.6rem 1.4rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  color: #e2e2e2;
  font-size: 1.6rem;
  transition: all 0.15s ease-in-out;
}

.btn-minwidth {
  min-width: 14rem;
}

.dash-button-success {
  background-color: rgb(0, 170, 113);
}
.dash-button-success:hover {
  background-color: rgba(0, 170, 113, 0.541);
  cursor: pointer;
}

.dash-button-danger {
  background-color: rgb(255, 82, 82);
}
.dash-button-danger:hover {
  background-color: rgba(255, 82, 82, 0.548);
  cursor: pointer;
}

.dash-button-info {
  background-color: rgb(45, 178, 255);
}
.dash-button-info:hover {
  background-color: rgba(45, 178, 255, 0.541);
  cursor: pointer;
}

.menu__button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 0;
  top: 1rem;
  width: 2.8rem;
  height: 2.8rem;
  margin-left: 1rem;
  background-color: #daa520;
  border-radius: 50%;
  transition: all 0.15s ease-in-out;
  color: #e2e2e2;
}
.menu__button:hover {
  background-color: #343442;
  cursor: pointer;
}
.menu__button .iconRotate {
  transform: rotate(180deg);
  transition: all 0.15s ease-in-out;
}

.switcher {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
  min-width: 3.6rem;
  height: 2.6rem;
  background-color: #2c2c38;
  border-radius: 10rem;
  margin: 0 1.5rem;
  border: 1px solid #3b3b3b;
}
.switcher__slider {
  position: absolute;
  width: 1.8rem;
  height: 1.8rem;
  border-radius: 10rem;
  transform: translate(0.5rem, -0.03rem);
  background: #daa520;
  transition: all 0.15s ease-in-out;
  margin: 0 !important;
}
.switcher__slider:hover {
  cursor: pointer;
}

@media screen and (min-width: 1920px) and (max-width: 2560px) {
  .category__layout-grid {
    grid-template-columns: 1fr 2fr 1fr;
  }
}
@media (max-width: 1280px) {
  .category__layout-grid {
    grid-template-columns: 1fr;
    grid-gap: 1rem;
  }
  .product {
    overflow-y: scroll;
    grid-template-columns: 1fr 1fr !important;
  }
}
@media screen and (max-width: 920px) {
  .aside__menu {
    max-width: 6rem !important;
  }
  .aside__menu-top {
    background: url(../assets/img/logos/Smilies-S-White-2022.svg) !important;
    background-position: left !important;
    background-size: 2.5rem !important;
    background-repeat: no-repeat !important;
  }
  .mobile-span-text {
    display: none;
  }
  .devider {
    margin-left: 0rem !important;
    width: 100% !important;
  }
  .product__container {
    grid-template-columns: 1fr !important;
    padding-bottom: 6rem;
  }
  .product__container button {
    position: fixed;
    bottom: 1rem;
    right: 2rem;
  }
  .table .column-small {
    width: 3rem;
  }
  .table .column-medium {
    width: 15rem;
  }
  .table .column-large {
    width: 10rem;
    display: none;
  }
  .menu__button {
    display: none;
  }
}
@media screen and (max-width: 768px) {
  .header__right {
    width: 100%;
  }
  .header .hamburger {
    display: block !important;
  }
  .aside__menu {
    display: none;
  }
  .image__thumbnail {
    display: grid;
    grid-template-columns: 2fr 2fr !important;
    height: 40rem !important;
    gap: 1rem !important;
  }
  .image__thumbnail img:hover {
    scale: 1 !important;
    transform: translate(0%, 0%) !important;
  }
  .payment {
    grid-template-columns: 1fr !important;
  }
  .payment .order__price {
    flex-direction: column;
    justify-content: flex-start;
  }
  .payment .order__price h3:nth-child(1) {
    margin-bottom: 1rem;
  }
}
@media screen and (max-width: 460px) {
  .table .column-small {
    width: 5rem !important;
  }
  .table .column-medium {
    width: 11rem !important;
  }
}
.table {
  height: calc(95% - 2rem);
  min-width: 100%;
}
.table table {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}
.table table thead {
  background-color: rgba(95, 45, 95, 0.192);
}
.table table tbody {
  height: 100%;
  overflow-y: scroll;
}
.table table tbody tr:hover {
  background-color: #343442;
  cursor: pointer;
}
.table table tr {
  display: flex;
  border-bottom: 1px solid #3b3b3b;
  padding: 0.4rem 0;
  width: 100%;
}
.table table tr th,
.table table tr td {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.table .column-x-small {
  width: 6rem;
  padding: 0 1rem;
  text-align: center;
}
.table .column-small {
  width: 8rem;
  padding: 0 1rem;
  text-align: center;
}
.table .column-medium {
  width: 15rem;
  padding: 0 1rem;
  text-align: center;
}
.table .column-large {
  width: 20rem;
  padding: 0 1rem;
  text-align: center;
}
.table .column-xxl {
  width: 23rem;
  padding: 0 1rem;
  text-align: center;
}
.table .options {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 10rem;
  padding: 0 1rem;
}
.table .info-cell {
  color: rgba(45, 178, 255, 0.6);
}

.container {
  width: 100%;
  height: 100svh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0;
}
.container h2 {
  font-weight: 300;
  font-size: 1.4rem;
  text-transform: uppercase;
  margin-top: 0.5rem;
}
.container img {
  height: 7rem;
  margin-top: 3rem;
}

@media (max-width: 768px) {
  .container img {
    height: 7rem;
  }
}
.error {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  width: 100%;
  height: 90svh;
  margin: 0 auto;
  padding: 1rem;
}
.error img {
  height: 7rem;
  margin-bottom: 2rem;
}
.error h1 {
  font-weight: 400;
}
.error h2 {
  text-transform: none;
  font-size: 2.3rem;
  text-align: center;
  font-weight: 300;
}
.error .error-link {
  text-decoration: underline;
  color: #daa520;
  cursor: pointer;
  margin-top: 1rem;
  font-size: 1.8rem;
}
.error .error-link:hover {
  color: gray;
}

.login-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0;
  position: relative;
  min-height: 75%;
}
.login-container .login-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0;
  position: absolute;
  z-index: 6;
  left: calc(50% - 17rem);
  width: 32rem;
  height: 44rem;
  background-color: #f5f5f5;
  box-shadow: 0px 1px 5px 1px rgba(0, 0, 0, 0.1176470588);
  padding: 2rem;
  margin-bottom: 4rem;
}
.login-container .login-card form {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 1rem;
  margin-bottom: 5rem;
}
.login-container .login-card form input::-moz-placeholder {
  color: #979797;
  font-weight: 100;
}
.login-container .login-card form input::placeholder {
  color: #979797;
  font-weight: 100;
}
.login-container .login-card form .form-imputs {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.login-container .login-card form .inputs {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  width: 100%;
  margin-top: 2rem;
  position: relative;
}
.login-container .login-card form .inputs p {
  font-size: 1.6rem;
}
.login-container .login-card form .inputs input,
.login-container .login-card form .inputs textarea {
  border: solid 1px rgba(216, 216, 216, 0.9725490196);
  width: 100%;
  padding: 0.5rem;
  font-size: 1.5rem;
  height: 3.2rem;
  letter-spacing: 0.1rem;
}
.login-container .login-card form .inputs input:focus {
  outline: 1px solid var(--color-acent);
}
.login-container .login-card form .inputs .remember {
  height: 1.6rem;
  width: 1.6rem;
  margin-right: 1rem;
  border: solid 1px rgba(216, 216, 216, 0.9725490196);
}
.login-container .login-card form .inputs label {
  color: var(--color-acent);
  line-height: 1.5rem;
  font-weight: 300;
}
.login-container .login-card form .inputs .btn {
  width: 50%;
  margin-top: 2rem;
}
.login-container .login-card form .check {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.login-container .signup {
  height: 53rem;
}
.login-container .login-card-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 4rem;
}
.login-container .login-card-header h1 {
  font-size: 1.6rem;
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 2px;
  font-weight: lighter;
  padding: 0rem;
}

.login-container .login-card .switch {
  position: absolute;
  top: 1rem;
  left: 2rem;
}
.login-container .login-card .switch span {
  color: #daa520;
  cursor: pointer;
  text-decoration: underline;
}

.loginCard {
  transform: rotateY(0deg);
  transition: var(--transition);
  transition: transform 0.3s linear;
  opacity: 1;
  z-index: 10;
}

.signupCard {
  transform: rotateY(90deg);
  /* transition: var(--transition); */
  transition: transform 0.3s linear;
  opacity: 0;
  z-index: 9;
}

.loginActive {
  transform: rotateY(90deg);
  opacity: 0;
  z-index: 9;
}

.sigupActive {
  transform: rotateY(0deg);
  opacity: 1;
  z-index: 10;
}

.visible__pass {
  position: absolute;
  right: 1rem;
  cursor: pointer;
  color: #979797;
}

.visible__pass:hover {
  color: var(--color-acent);
}

.pass__reset {
  text-decoration: underline;
  color: #daa520;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 2rem;
  cursor: pointer;
}

@media (max-width: 768px) {
  .login-container {
    height: 60rem;
  }
}
.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
  background-color: transparent;
}
.loader-container .loader {
  background: transparent;
  overflow: hidden;
  position: relative;
  height: 3rem;
  width: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.loader-container .loader p {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 1rem;
}
.loader-container .loader span {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 3rem;
  width: 3rem;
  border: 5px solid rgba(69, 71, 75, 0.5137254902);
  border-top-color: #daa520;
  border-radius: 50%;
  animation: rotation 1.2s infinite linear;
}

@keyframes rotation {
  100% {
    transform: rotate(360deg);
  }
}
.aside__menu {
  position: fixed;
  top: 0;
  bottom: 0;
  width: 26rem;
  height: 100vh;
  transition: all 0.3s ease-in-out;
  background-color: #2c2c38;
  border-right: 1px solid #3b3b3b;
  position: relative;
  z-index: 5;
  color: #8d8d8d;
}
.aside__menu-top {
  height: 6rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-left: 1.8rem;
  margin-bottom: 3rem;
  cursor: pointer;
  background: url(../assets/img/logos/Smilies-White-2022.svg);
  background-position: left;
  background-size: 11rem;
  background-repeat: no-repeat;
}
.aside__menu-bottom {
  position: relative;
  z-index: 8;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  /* Theme Switch */
}
.aside__menu-bottom-content {
  width: 100%;
  z-index: 9;
  overflow-x: hidden;
}
.aside__menu-bottom-content .group {
  height: 3.8rem;
  overflow: hidden;
}
.aside__menu-bottom-content .group-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 3.8rem;
  color: #8d8d8d;
  cursor: pointer;
  position: relative;
  transition: all 0.3s ease-in-out;
}
.aside__menu-bottom-content .group-header .link-style {
  font-size: 1.6rem;
  position: absolute;
  margin-left: 3rem;
  color: #8d8d8d;
  display: flex;
  align-items: center;
  opacity: 1;
  text-decoration: none;
}
.aside__menu-bottom-content .group-header p {
  font-size: 1.6rem;
  position: absolute;
  margin-left: 6rem;
  color: #8d8d8d;
  display: flex;
  align-items: center;
  opacity: 1;
}
.aside__menu-bottom-content .group-header .order__product-count {
  left: 3.2rem;
  top: 1.5rem;
  position: absolute;
}
.aside__menu-bottom-content .group-header .order__product-count h3 {
  font-size: 1.2rem;
  text-decoration: none;
}
.aside__menu-bottom-content .group-content {
  border-left: 1px solid #3b3b3b;
  margin: 0 0 0 1.8rem;
  padding-left: 0.5rem;
  overflow: hidden;
  transition: all 0.15s ease-in-out;
}
.aside__menu-bottom-content .group-content li {
  margin: 0.5rem 0;
  width: 90%;
  height: 3.4rem;
  color: #8d8d8d;
  transition: all 0.15s ease-in-out;
  text-transform: capitalize;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.aside__menu-bottom-content .group-content li span {
  font-size: 1.4rem;
  margin: 0rem !important;
  position: absolute;
  padding-left: 4rem;
  opacity: 0;
}
.aside__menu-bottom-content .group-content li:hover {
  cursor: pointer;
  color: #daa520;
}
.aside__menu .theme__switch {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 5rem;
  background-color: #242430;
  position: absolute;
  bottom: 0;
  left: 0;
  color: #8d8d8d;
}

.link-style-group {
  font-size: 1.6rem;
  position: absolute;
  margin-left: 0.6rem;
  color: #8d8d8d;
  display: flex;
  align-items: center;
  opacity: 1;
  width: 14rem;
  text-decoration: none;
}
.link-style-group:hover {
  color: #daa520;
}

.closeAside {
  width: 6rem !important;
  transition: all 0.3s ease-in-out;
}

.aside__menu.closeAside .aside__menu-top {
  background: url(../assets/img/logos/Smilies-S-White-2022.svg);
  background-position: left;
  background-size: 2.5rem;
  background-repeat: no-repeat;
}

.toggleHeader {
  min-height: 13rem;
  background-color: #242430;
  border: 1px solid rgba(217, 165, 33, 0.3);
}

.toggleHeader-xl {
  min-height: 28rem;
  background-color: #242430;
  border: 1px solid rgba(217, 165, 33, 0.3);
}

.group.toggleHeader .group-content span {
  opacity: 1;
}

.li-active {
  background-color: #daa520;
}

.group-hover:hover {
  background-color: #343442;
  transition: all 0.15s ease-in-out;
}

.devider__aside {
  border-bottom: 1px dotted #757575;
  width: 18rem;
  margin-bottom: 2.2rem;
  transform: translateX(2rem);
  transition: transform 0.3s ease-in-out;
}

.aside__menu.closeAside .aside__menu-bottom .devider__aside {
  margin-left: 0rem !important;
  transform: translateX(0rem);
}

.header {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 5;
  background-color: #2c2c38;
  min-width: 100%;
  height: 5rem;
  border-bottom: 1px solid #3b3b3b;
  padding: 1rem 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: all 0.15s ease-in-out;
  color: #8d8d8d;
}
.header__left {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 20rem;
}
.header__right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.header__right .dash__user {
  text-transform: capitalize;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  line-height: 1.2;
  margin-right: 2rem;
}
.header__right .dash__user span:nth-child(2) {
  color: #daa520;
  font-style: italic;
  font-size: 1.3rem;
}
.header .close-menu {
  display: none;
}
.header .hamburger {
  display: none;
}

.category__container {
  width: 100%;
  height: 28rem;
  background-color: #2c2c38;
  border-radius: 0.5rem;
  box-shadow: 0px 1px 5px 1px rgba(0, 0, 0, 0.1176470588);
}
.category__container-header {
  height: 3.5rem;
  background-color: #383846;
  border-radius: 0.5rem 0.5rem 0 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.category__container-header h2 {
  font-size: 1.8rem;
  text-transform: capitalize;
  margin-left: 2rem;
}
.category__container-inputs {
  height: calc(100% - 4rem);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
}
.category__container-inputs label {
  font-size: 1.5rem;
}
.category__container-inputs input[type=text],
.category__container-inputs select {
  width: 100%;
  height: 3.6rem;
  padding: 0 1rem;
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}
.category__container-inputs .select-input {
  display: flex;
  flex-direction: column;
}
.category__container form {
  width: 100%;
}
.category__container form .category__container-inputs-content-buttons {
  display: flex;
  justify-content: space-between;
}
.category__container form .category__container-inputs-content-buttons button {
  position: relative;
}
.category__container form .category__container-inputs-content-buttons button .dash-button-regular {
  min-width: 5rem !important;
}

.addCard-hide {
  display: none;
}

.updateCard {
  display: none;
}

.updateCard-show {
  display: block;
}

.container-height {
  height: 35rem;
}

.category-list {
  min-height: 100%;
  width: 100%;
}
.category-list .category__container-inputs {
  padding: 1rem;
  width: 100%;
}
.category-list .category__container-inputs .list__search {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 4rem;
  margin-bottom: 1rem;
  width: 100%;
}
.category-list .category__container-inputs .list__search .search-input {
  border: 1px solid #3b3b3b;
  display: flex;
  align-items: center;
  width: 25rem;
  padding-left: 1rem;
}
.category-list .category__container-inputs .list__search input[type=text] {
  height: 3.3rem;
  background-color: transparent;
  border: none;
  color: #8d8d8d;
  margin: 0rem;
  padding: 0rem;
}
.category-list .category__container-inputs .list__search input[type=text]:focus {
  outline: none;
}

.product {
  box-shadow: 0px 1px 5px 1px rgba(0, 0, 0, 0.1176470588);
  background-color: #2c2c38;
  border-radius: 0.5rem;
  min-height: calc(100vh - 9rem);
}
.product .category__container-header {
  background-color: #383846;
  border-radius: 0.5rem 0.5rem 0 0;
}
.product .product__container {
  position: relative;
  width: 100%;
  display: grid;
  grid-template-columns: 2fr 2fr 3fr;
}
.product .product__container-inputs {
  padding: 2rem;
}
.product .product__container-inputs .fileContainer {
  background: #242430;
  border-radius: 4px;
  border: 1px solid #3b3b3b;
}
.product .product__container-inputs .fileContainer .chooseFileButton {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0;
  padding: 1rem;
  border-radius: 50%;
  background-color: #383846;
  margin-bottom: 1.5rem;
}
.product .product__container-inputs .fileContainer .chooseFileButton:hover {
  background-color: #2c2c38;
}
.product .product__container-inputs .fileContainer .deleteImage {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0;
  background-color: rgb(255, 82, 82);
  font-size: 1.8rem;
  width: 2.6rem;
  height: 2.6rem;
  transition: all 0.15s ease-in-out;
}
.product .product__container-inputs .fileContainer .deleteImage:hover {
  background-color: rgba(255, 82, 82, 0.6);
}
.product .product__container-inputs .select__group {
  display: flex;
  width: 100%;
  gap: 1rem;
}
.product .product__container-inputs .select__group-content {
  width: 50%;
}
.product .product__container-inputs input[type=text],
.product .product__container-inputs select {
  width: 100%;
  height: 3.6rem;
  padding: 0 1rem;
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}
.product .product__container-inputs textarea {
  width: 100%;
  margin-bottom: 1rem;
  padding: 1rem;
  resize: none;
}
.product .product__container-inputs input[type=file] {
  display: none;
  width: 100%;
}
.product .product__container-inputs label {
  font-size: 1.5rem;
}
.product .product__container-inputs p {
  font-size: 1.6rem;
}
.product .product__container button {
  position: absolute;
  bottom: 0rem;
  right: 2rem;
  margin-bottom: 1rem;
}

.input-flex {
  display: flex;
  flex-direction: column;
}

.label-success {
  color: rgba(0, 170, 113, 0.6);
}

.label-danger {
  color: rgba(255, 82, 82, 0.6);
}

.input-file-styling {
  width: 100%;
  height: 3.6rem;
  background-color: #242430;
  border-radius: 4px;
  border: 1px solid #3b3b3b;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.header__menu {
  position: absolute;
  top: 6rem;
  right: 4rem;
  z-index: 3;
  min-width: 14rem;
  padding: 0.5rem 0;
  background-color: #2c2c38;
  border-radius: 4px;
  border: 1px solid #3b3b3b;
  box-shadow: 0px 1px 5px 1px rgba(0, 0, 0, 0.1176470588);
  color: #8d8d8d;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  transition: all 0.3s ease-in-out;
  transform-origin: top;
  transform: rotateX(-90deg);
}
.header__menu-options {
  text-transform: capitalize;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0.5rem 1.5rem;
}
.header__menu-options:hover {
  background-color: #343442;
  cursor: pointer;
}
.header__menu-options span {
  padding-left: 1rem;
}
.header__menu-options span:hover {
  color: #8d8d8d;
}

.headerMenuActive {
  transform: rotateX(0deg);
  transition: all 0.3s ease-in-out;
}

.order__status {
  width: 1.2rem;
  height: 1.2rem;
  border-radius: 50%;
}

.order__status-danger {
  background-color: rgba(255, 82, 82, 0.6);
}

.order__status-info {
  background-color: rgba(45, 178, 255, 0.6);
}

.order__status-success {
  background-color: rgba(0, 170, 113, 0.6);
}

.order__status-default {
  background-color: rgba(128, 128, 128, 0.5);
}

.details__window {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 100;
  width: 100rem;
  height: 100vh;
  transform: translateX(100%);
  transition: all 0.3s ease-in-out;
  display: flex;
  background-color: #2c2c38;
  border-left: 1px solid #3b3b3b;
  overflow-y: scroll;
}
.details__window-details {
  width: 100%;
  margin: 0px;
}
.details__window .icon-position {
  position: fixed;
  right: 2rem;
  top: 2rem;
}

.detailsActive {
  transform: translateX(0%);
}

@media screen and (max-width: 1024px) {
  .details__window {
    width: 100vw;
  }
}
.order__dash-details {
  padding: 0 2rem;
  min-height: 100vh;
}
.order__dash-details h3 {
  text-decoration: underline;
  margin-bottom: 1rem;
  margin-top: 2rem;
}
.order__dash-details .details__container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  margin-top: 1rem;
}
.order__dash-details .details__container form {
  display: flex;
  flex-direction: row;
  gap: 2rem;
  width: 100%;
  height: 100%;
}
.order__dash-details .details__container form .form__inputs {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.order__dash-details .details__container form .form__inputs-different {
  display: flex;
  width: 100%;
}
.order__dash-details .details__container .order__product-count {
  background-color: #daa520;
}
.order__dash-details .details__container .order__product-count h3 {
  text-decoration: none;
}
.order__dash-details .details__container .ordered {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;
  margin-bottom: 1rem;
}
.order__dash-details .details__container .details__products,
.order__dash-details .details__container .details__price {
  display: flex;
  flex-wrap: wrap;
  min-height: 13rem;
  gap: 1rem;
}
.order__dash-details .details__container .details__products-img,
.order__dash-details .details__container .details__price-img {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: flex-start;
}
.order__dash-details .details__container .details__products-img-content,
.order__dash-details .details__container .details__price-img-content {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 1rem;
  border: 1px solid #3b3b3b;
  border-radius: 4px;
  background-color: #242430;
}
.order__dash-details .details__container .details__products-img-content h3,
.order__dash-details .details__container .details__price-img-content h3 {
  font-size: 1.4rem;
  text-decoration: none;
}
.order__dash-details .details__container .details__products-img-content img,
.order__dash-details .details__container .details__price-img-content img {
  -o-object-fit: cover;
     object-fit: cover;
  height: 13rem;
  width: 100%;
}
.order__dash-details .details__container .details__products-info,
.order__dash-details .details__container .details__price-info {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 1rem;
  border: 1px solid #3b3b3b;
  border-radius: 4px;
  background-color: #242430;
  width: 33rem;
}
.order__dash-details .details__container .details__products-info h3,
.order__dash-details .details__container .details__price-info h3 {
  font-size: 1.6rem;
  text-decoration: none;
}
.order__dash-details .details__container .details__price {
  min-height: 2rem;
}
.order__dash-details .details__container .details__options {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
}
.order__dash-details .details__container .details__options-inner {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 8rem;
  height: 3rem;
  background-color: #242430;
  border: 1px solid #3b3b3b;
  border-radius: 4px;
}

@media screen and (max-width: 768px) {
  .order__dash-details .details__container form {
    flex-direction: column;
  }
}
.logList_container {
  width: 100%;
  height: 100%;
}
.logList_container-options {
  display: flex;
  align-items: flex-end;
  margin-bottom: 1.5rem;
  width: 100%;
  gap: 2rem;
}
.logList_container-options .custom-margin {
  margin-bottom: 0.3rem;
}
.logList_container-options .logButton {
  background-color: transparent;
  border: none;
}
.logList_container-options .option--container {
  display: flex;
  flex-direction: column;
  width: 30rem;
}
.logList_container-options .option--container select,
.logList_container-options .option--container input {
  width: 100%;
  height: 3.6rem;
  padding: 0 1rem;
  display: flex;
  align-items: center;
}
.logList_container article {
  width: 100%;
  height: calc(100% - 1rem - 1rem - 4rem - 2rem - 3rem);
  padding: 1rem;
  background-color: rgba(0, 0, 0, 0.267);
  border-radius: 4px;
  overflow-y: scroll;
  white-space: break-spaces;
}

.filters__container {
  width: 22rem;
  margin-top: 6rem;
}

.mobile__container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 0;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #2c2c38;
  z-index: 100%;
  padding: 2rem 1rem;
  transform: translateX(-100%);
  transition: all 0.15s ease-in-out;
}
.mobile__container .mobile-menu-top {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 0;
  width: 100%;
  height: 6rem;
  margin-left: 1.8rem;
  margin-bottom: 3rem;
  cursor: pointer;
}
.mobile__container .mobile-menu-top img {
  height: 6rem;
}
.mobile__container .aside__menu-bottom-content {
  width: 100%;
}

.mobileActive {
  transform: translateX(0);
}

.closeMobileMenu {
  position: absolute;
  top: 2rem;
  right: 2rem;
  cursor: pointer;
  font-size: 2.3rem;
}

.moveIcon {
  margin-left: 12.8rem;
}

.modal {
  background-color: rgba(0, 0, 0, 0.6);
  transition: all 0.3s ease-in-out;
  position: fixed;
  visibility: none;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
  z-index: 0;
}

.modalActive {
  visibility: visible;
  display: block;
  z-index: 30;
}

@media (max-width: 768px) {
  .modal span {
    top: 2rem;
    right: 2rem;
  }
}
.products__container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  min-height: 8.2rem;
  gap: 1rem;
}
.products__container .single__sontainer {
  width: 100%;
  min-height: 8rem;
  background-color: #2c2c38;
  border-radius: 5px;
  padding: 1.5rem;
  transition: all 0.15s ease-in-out;
  box-shadow: 0px 1px 5px 1px rgba(0, 0, 0, 0.1176470588);
  position: relative;
}
.products__container .single__sontainer:hover {
  transform: translateY(-0.3rem);
}
.products__container .single__sontainer:hover::after {
  content: "";
  display: block;
  width: 100%;
  height: 0.2rem;
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: #daa520;
  border-radius: 0px 0px 5px 5px;
}
.products__container .single__sontainer h3 {
  font-weight: 300;
  color: #daa520;
}
.products__container .single__sontainer .product-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 0rem;
  margin-bottom: 1.5rem;
}
.products__container .single__sontainer .product-header .product-info {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 2rem;
}
.products__container .single__sontainer .product-header .product-options {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
}
.products__container .single__sontainer .product-middle {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  gap: 2rem;
}
.products__container .single__sontainer .product-middle img {
  height: 9rem;
}
.products__container .single__sontainer .product-footer {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  gap: 0;
}

@media screen and (max-width: 1280px) {
  .products__container {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media screen and (max-width: 768px) {
  .products__container {
    grid-template-columns: 1fr;
  }
}
.search {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 0;
  padding: 2rem 2rem 0rem 2rem;
  background-color: #242430;
  color: #8d8d8d;
  padding-bottom: 2rem;
}
.search .search-input {
  border: 1px solid #3b3b3b;
  display: flex;
  align-items: center;
  width: 30rem;
  padding-left: 1rem;
}
.search input[type=text] {
  height: 3.5rem;
  background-color: transparent;
  border: none;
  color: #8d8d8d;
  margin: 0rem;
  padding: 0rem;
}
.search input[type=text]:focus {
  outline: none;
}

@media screen and (max-width: 768px) {
  .search .search-input {
    width: 100%;
  }
}
.confirm__message {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.3);
  overflow: none;
  display: flex;
  justify-content: center;
  align-items: center;
}
.confirm__message .confirm__message-container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  height: 16rem;
  width: 46rem;
  background-color: #fff;
  padding: 1rem;
  margin: 0 auto;
}
.confirm__message-buttons {
  display: flex;
  gap: 1rem;
}

@media (max-width: 720px) {
  .confirm__message {
    align-items: flex-start;
  }
}/*# sourceMappingURL=main.css.map */