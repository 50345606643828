.aside__menu {
    position: fixed;
    top: 0;
    bottom: 0;
    width: 26rem;
    height: 100vh;
    transition: $dash-transition-03;
    background-color: $dash-main;
    border-right: $dash-border;
    position: relative;
    z-index: 5;
    color: $dash-txt;

    &-top {
        height: 6rem;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        margin-left: 1.8rem;
        margin-bottom: 3rem;
        cursor: pointer;
        background: url(../assets/img/logos/Smilies-White-2022.svg);
        background-position: left;
        background-size: 11rem;
        background-repeat: no-repeat;
    }

    &-bottom {
        position: relative;
        z-index: 8;
        display: flex;
        align-items: flex-start;
        flex-direction: column;

        &-content {
            width: 100%;
            z-index: 9;
            // overflow-y: scroll;
            overflow-x: hidden;

            .group {
                height: 3.8rem;
                overflow: hidden;
                // margin-bottom: .2rem;

                &-header {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    min-height: 3.8rem;

                    color: $dash-txt;
                    cursor: pointer;
                    position: relative;
                    transition: $dash-transition-03;

                    .link-style {
                        font-size: 1.6rem;
                        position: absolute;
                        margin-left: 3rem;
                        color: $dash-txt;
                        display: flex;
                        align-items: center;
                        opacity: 1;
                        text-decoration: none;
                    }

                    p {

                        font-size: 1.6rem;
                        position: absolute;
                        margin-left: 6rem;
                        color: $dash-txt;
                        display: flex;
                        align-items: center;
                        opacity: 1;
                    }

                    .order__product-count {
                        // min-width: 1.8rem;
                        // min-height: 1.8rem;
                        left: 3.2rem;
                        top: 1.5rem;
                        position: absolute;

                        h3 {
                            font-size: 1.2rem;
                            text-decoration: none;

                        }
                    }
                }

                &-content {

                    border-left: $dash-border;
                    margin: 0 0 0 1.8rem;
                    padding-left: .5rem;
                    overflow: hidden;
                    transition: $dash-transition-015;

                    & li {
                        margin: .5rem 0;
                        width: 90%;
                        height: 3.4rem;
                        color: $dash-txt;
                        transition: $dash-transition-015;
                        text-transform: capitalize;
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;

                        & span {
                            font-size: 1.4rem;
                            margin: 0rem !important;
                            position: absolute;
                            padding-left: 4rem;
                            opacity: 0;

                            // transition: $dash-transition-015
                        }

                        &:hover {
                            cursor: pointer;
                            color: $dash-accent;
                        }
                    }
                }
            }

        }

        /* Theme Switch */
    }

    .theme__switch {
        @include flex-row-center-center;

        width: 100%;
        height: 5rem;
        // height: 4.5rem;
        background-color: $dash-second;
        position: absolute;
        bottom: 0;
        left: 0;
        color: $dash-txt;
    }
}

.link-style-group {

    font-size: 1.6rem;
    position: absolute;
    margin-left: .6rem;
    color: $dash-txt;
    display: flex;
    align-items: center;
    opacity: 1;
    width: 14rem;
    text-decoration: none;

    &:hover {
        color: $dash-accent;
    }
}

// Classes when aside menu is closing
.closeAside {
    width: 6rem !important;
    transition: $dash-transition-03;
}

.aside__menu.closeAside .aside__menu-top {
    background: url(../assets/img/logos/Smilies-S-White-2022.svg);
    background-position: left;
    background-size: 2.5rem;
    background-repeat: no-repeat;
}

.toggleHeader {
    min-height: 13rem;
    background-color: $dash-second;
    border: 1px solid $dash-accent-muted;
}

.toggleHeader-xl {
    min-height: 28rem;
    background-color: $dash-second;
    border: 1px solid $dash-accent-muted;
}

.group.toggleHeader .group-content span {
    opacity: 1;
}

.li-active {
    background-color: $dash-accent;
}

.group-hover {

    &:hover {
        background-color: $dash-hover;
        transition: $dash-transition-015;
    }
}

.devider__aside {
    border-bottom: 1px dotted #757575;
    width: 18rem;
    margin-bottom: 2.2rem;
    transform: translateX(2rem);
    transition: transform .3s ease-in-out;
}

.aside__menu.closeAside .aside__menu-bottom .devider__aside {
    margin-left: 0rem !important;
    transform: translateX(0rem);
}

// .closedDevider {
//     margin-left: 0rem !important;
//     width: 100% !important;
// }