.logList_container {

    width: 100%;
    height: 100%;

    &-options {
        display: flex;
        align-items: flex-end;
        // flex-direction: column;
        margin-bottom: 1.5rem;
        width: 100%;
        gap: 2rem;

        .custom-margin {
            margin-bottom: .3rem;
        }

        .logButton {
            background-color: transparent;
            border: none;
        }

        .option--container {
            display: flex;
            flex-direction: column;
            width: 30rem;

            select,
            input {
                width: 100%;
                height: 3.6rem;
                padding: 0 1rem;
                display: flex;
                align-items: center;
            }
        }

    }

    article {
        width: 100%;
        height: calc(100% - 1rem - 1rem - 4rem - 2rem - 3rem);
        padding: 1rem;
        background-color: rgba(0, 0, 0, 0.267);
        border-radius: $dash-radius;
        overflow-y: scroll;

        white-space: break-spaces;
    }

}