.error {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    width: 100%;
    height: 90svh;
    margin: 0 auto;
    padding: 1rem;

    img {
        height: 7rem;
        margin-bottom: 2rem;
    }
    
    h1 {
        font-weight: 400;
    }
    
    h2 {
        text-transform: none;
        font-size: 2.3rem;
        text-align: center;
        font-weight: 300;
    }
    
    .error-link {
        text-decoration: underline;
        color: $dash-accent;
        cursor: pointer;
        margin-top: 1rem;
        font-size: 1.8rem;

        &:hover {
            color: gray;
        }
    }
}
