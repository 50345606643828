.loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    // height: 100vh;
    // width: 100vw;
    position: absolute;
    top: 50%;
    left:50%;
    transform: translate(-50%, -50%);
    z-index: 100;
    background-color: transparent;

    .loader {
        background: transparent;
        overflow: hidden;
        position: relative;
        height: 3rem;
        width: 3rem;
        display: flex;
        justify-content: center;
        align-items: center;

        p {
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;
            font-weight: 600;
            font-size: 1rem;
        }

        span {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 3rem;
            width: 3rem;
            border: 5px solid #45474b83;
            border-top-color: #daa520;
            border-radius: 50%;
            animation: rotation 1.2s infinite linear;
        }
    }
}
  
  
  @keyframes rotation {
    100% {
      transform: rotate(360deg);
    }
  }