@mixin flex-row-center-center {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

@mixin flex($direction, $align, $justify, $gap) {
    display: flex;
    flex-direction: $direction;
    align-items: $align;
    justify-content: $justify;
    gap: $gap;
}

@mixin margin($marginl, $marginr) {
    margin-left: $marginl;
    margin-right: $marginr;
}

@mixin radius ($a, $b, $c, $d) {
    border-radius: $a, $b, $c, $d;
}