.table {
    height: calc(100% - 5% - 2rem);
    min-width: 100%;

    table {
        display: flex;
        flex-direction: column;
        height: 100%;
        width: 100%;

        thead {
            background-color: $dash-table-head;
        }

        tbody {
            height: 100%;
            overflow-y: scroll;

            tr {
                &:hover {
                    background-color: $dash-hover;
                    cursor: pointer;
                }
            }
        }

        tr {
            display: flex;
            border-bottom: $dash-border;
            padding: .4rem 0;
            width: 100%;

            th,
            td {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                // width: 100%;
                // width: 10rem;
            }
        }
    }

    .column-x-small {
        width: 6rem;
        padding: 0 1rem;
        text-align: center;
    }

    .column-small {
        width: 8rem;
        padding: 0 1rem;
        text-align: center;
    }

    .column-medium {
        width: 15rem;
        padding: 0 1rem;
        text-align: center;
    }

    .column-large {
        width: 20rem;
        padding: 0 1rem;
        text-align: center;
    }

    .column-xxl {
        width: 23rem;
        padding: 0 1rem;
        text-align: center;
    }

    .options {
        display: flex;
        align-items: center;
        justify-content: space-around;
        width: 10rem;
        padding: 0 1rem;
    }

    .info-cell {
        color: $dash-info-muted;
    }
}