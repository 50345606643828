.order__dash-details {
    padding: 0 2rem;
    min-height: 100vh;

    h3 {
        text-decoration: underline;
        margin-bottom: 1rem;
        margin-top: 2rem;
    }

    .details__container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
        height: 100%;
        margin-top: 1rem;

        form {
            display: flex;
            flex-direction: row;
            gap: 2rem;
            width: 100%;
            height: 100%;

            .form__inputs {
                display: flex;
                flex-direction: column;
                flex: 1;

                &-different {
                    display: flex;
                    width: 100%;
                }
            }
        }

        .order__product-count {
            background-color: $dash-accent;

            h3 {
                text-decoration: none;
            }
        }

        .ordered {
            @include flex(row, center, flex-start, 1rem);
            margin-bottom: 1rem;
        }

        .details__products,
        .details__price {
            display: flex;
            flex-wrap: wrap;
            min-height: 13rem;
            gap: 1rem;

            &-img {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                gap: 1rem;
                justify-content: flex-start;

                &-content {
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    // margin-right: 1rem;
                    padding: 1rem;
                    border: $dash-border;
                    border-radius: $dash-radius;
                    background-color: $dash-second;

                    h3 {
                        font-size: 1.4rem;
                        text-decoration: none;
                    }

                    img {
                        object-fit: cover;
                        height: 13rem;
                        width: 100%;
                    }
                }
            }

            &-info {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                padding: 1rem;
                border: $dash-border;
                border-radius: $dash-radius;
                background-color: $dash-second;
                width: 33rem;
                // margin-bottom: 1rem;

                h3 {
                    font-size: 1.6rem;
                    text-decoration: none;
                }
            }
        }

        .details__price {
            min-height: 2rem;
        }

        .details__options {

            display: flex;
            justify-content: flex-end;
            align-items: center;
            width: 100%;

            &-inner {
                display: flex;
                justify-content: center;
                align-items: center;
                min-width: 8rem;
                height: 3rem;
                background-color: $dash-second;
                border: $dash-border;
                border-radius: $dash-radius;
                // margin: 1rem;
            }
        }
    }
}


@media screen and (max-width: 768px) {
    .order__dash-details {

        .details__container {

            form {
                flex-direction: column;
            }
        }
    }
}