@media screen and (min-width:1920px) and (max-width:2560px) {
    .category__layout-grid {
        grid-template-columns: 1fr 2fr 1fr;
    }
}

@media (max-width:1280px) {

    .category__layout-grid {
        grid-template-columns: 1fr;
        grid-gap: 1rem
    }


    .product {
        overflow-y: scroll;
        grid-template-columns: 1fr 1fr !important;
    }

}

@media screen and (max-width:920px) {
    // .aside__menu {
    //     min-width: 6rem !important;
    // }

    .aside__menu {
        max-width: 6rem !important;

        &-top {

            background: url(../assets/img/logos/Smilies-S-White-2022.svg) !important;
            background-position: left !important;
            background-size: 2.5rem !important;
            background-repeat: no-repeat !important;

        }
    }

    .mobile-span-text {
        display: none;
    }

    .devider {
        margin-left: 0rem !important;
        width: 100% !important;
    }

    .product__container {
        grid-template-columns: 1fr !important;

        padding-bottom: 6rem;

        button {
            position: fixed;
            bottom: 1rem;
            right: 2rem;
        }
    }


    .table {

        .column-small {
            width: 3rem;
        }

        .column-medium {
            width: 15rem;
        }

        .column-large {
            width: 10rem;
            display: none;
        }

    }

    .menu__button {
        display: none;
    }

    // .close-menu {
    //     display: block !important;
    // }

    // .category__layout-grid {
    //     grid-template-columns: 1fr;
    // }

}

@media screen and (max-width:768px) {

    .header {

        &__right {
            width: 100%;
        }

        .hamburger {
            display: block !important;
        }
    }

    .aside__menu {
        display: none;
    }


    .image__thumbnail {
        display: grid;
        grid-template-columns: 2fr 2fr !important;
        height: 40rem !important;
        gap: 1rem !important;

        img {

            &:hover {
                scale: 1 !important;
                transform: translate(0%, 0%) !important;
            }
        }
    }

    .payment {
        grid-template-columns: 1fr !important;


        .order__price {
            flex-direction: column;
            justify-content: flex-start;

            & h3:nth-child(1) {
                margin-bottom: 1rem;
            }
        }
    }
}

@media screen and (max-width:460px) {

    .table {

        .column-small {
            width: 5rem !important;
        }

        .column-medium {
            width: 11rem !important;
        }
    }
}